<template>
  <div>
    <div class="section-title section-title_app">
      <h3>Especialidades Medicas</h3>
    </div>
    <div class="columns is-multiline is-gapless">
      <div class="column is-12 has-background-white">
        <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
          <b-tooltip label="Primer orden">
            <b-button icon-left="circle-arrow-left"
                      size="is-large"
                      type="is-primary"
                      @click="navigate('first')"></b-button>
          </b-tooltip>
          <b-tooltip label="Orden anterior">
            <b-button icon-left="arrow-left"
                      size="is-large"
                      type="is-primary"
                      @click="navigate('preview')"></b-button>
          </b-tooltip>
          <b-tooltip label="Orden siguiente">
            <b-button icon-left="arrow-right"
                      size="is-large"
                      type="is-primary"
                      @click="navigate('next')"></b-button>
          </b-tooltip>
          <b-tooltip label="Última orden">
            <b-button icon-left="circle-arrow-right"
                      size="is-large"
                      type="is-primary"
                      @click="navigate('last')"></b-button>
          </b-tooltip>
          <b-tooltip label="Historico" v-if="!!data.ordenNew && $alfalab.isAdmin($auth.user())">
            <b-button
                @click="showHistoryModal=true"
                size="is-large">H</b-button>
          </b-tooltip>
        </div>
      </div>

      <div class="column is-12 has-background-white is-multiline">
        <div class="columns is-12 column is-multiline">
          <div class="column is-12 cancel_message" style="padding: 1.25rem;">
            <b-message
                v-if="data.is_cancel"
                type="is-danger">
              Orden cancelada
            </b-message>
          </div>
          <div class="column is-2">
            <b-field label="Año"
                     label-position="on-border">
              <b-select placeholder="2022" v-model="year">
                <option :value="year.value" :key="year.value" v-for="year in yearsRange">{{ year.text }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Fecha"
                     label-position="on-border">
              <b-input :disabled="true" v-model="data.orderDate"></b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Orden"
                     label-position="on-border">
              <b-input v-model="order" @keypress.native.enter="getData"></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Código de barras"
                     label-position="on-border">
              <b-input v-model="barcode" @keypress.native.enter="getDataByBarcode"></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Procedencia"
                     label-position="on-border">
              <b-input :disabled="true" v-model="data.branchOfficeName"></b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="No.Local"
                     label-position="on-border">
              <b-input :disabled="true" v-model="data.localNumber"></b-input>
            </b-field>
          </div>
          <div class="column is-10">
            <b-field label="Empresa"
                     label-position="on-border">
              <b-input :disabled="true" v-model="data.company.name" v-if="data.company"></b-input>
              <b-input :disabled="true" v-else></b-input>
            </b-field>
          </div>
          <div class="column is-12">
            <b-field label="Paciente"
                     label-position="on-border">
              <b-input :disabled="true" v-model="data.patient.full_name" v-if="data.patient"></b-input>
              <b-input :disabled="true" v-else></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Sexo"
                     label-position="on-border">
              <b-input :disabled="true" v-model="data.patient.gender" v-if="data.patient"></b-input>
              <b-input :disabled="true" v-else></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Edad"
                     label-position="on-border">
              <b-input :disabled="true" v-model="data.patient.age" v-if="data.patient"></b-input>
              <b-input :disabled="true" v-else></b-input>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field label="Medico Solicitante"
                     label-position="on-border">
              <b-input :disabled="true" v-model="data.medical.name" v-if="data.medical"></b-input>
              <b-input :disabled="true" v-else></b-input>
            </b-field>
          </div>
        </div>

        <div class="columns is-12 is-multiline">
          <div :class="`column ${expandHistory ? 'is-9' : 'is-11'}`">
            <section>
              <b-tabs type="is-toggle"
                      multiline
                      @input="handleClick"
                      size="default"
              >
                <b-tab-item label="Electrocardiograma en Reposo" value="electro">
                  <electrocariogram :order=data :dataElectro="dataElectro"
                                     v-if="data.patient &&data.specialties.Electro"></electrocariogram>
                  <div v-else>
                    <section class="section">
                      <div class="content has-text-grey has-text-centered">
                        <p><span class="icon is-large"><i class="fas fa-box-open fa-2x"></i></span></p>
                        <p>Esta orden no contiene electrocardiograma</p>
                      </div>
                    </section>
                  </div>
                </b-tab-item>
                <b-tab-item label="Prueba de esfuerzo" value="esfprue" v-if="false">
                  <stress-test :order="data" :dataStressTest="dataStressTest"
                                v-if="data.patient && data.specialties.StressTest"></stress-test>
                  <div v-else>
                    <section class="section">
                      <div class="content has-text-grey has-text-centered">
                        <p><span class="icon is-large"><i class="fas fa-box-open ga-2x"></i></span></p>
                        <p>Esta orden no contiene prueba de esfuerzo</p>
                      </div>
                    </section>
                  </div>
                </b-tab-item>
                <b-tab-item v-if="false" label="Valor Cardiovascular" value="cardio"></b-tab-item>
                <b-tab-item v-if="false" label="Audiometria" value="audio">
                  <div class="column is-12 has-background-white is-multiline">
                    <div class="columns is-12 column is-multiline">
                      <div class="column is-4">
                        <b-field label="Categoría"
                                 label-position="on-border">
                          <b-input></b-input>
                        </b-field>
                      </div>
                      <div class="column is-4">
                        <b-field label="Departamento"
                                 label-position="on-border">
                          <b-input></b-input>
                        </b-field>
                      </div>
                      <div class="column is-2">
                        <b-field>
                          <b-checkbox>Firmada</b-checkbox>
                        </b-field>
                      </div>
                      <div class="column is-2">
                        <b-field>
                          <b-checkbox>Forma anterior</b-checkbox>
                        </b-field>
                      </div>
                      <b-tabs type="is-toggle">
                        <b-tab-item label="Antecedentes H-F"></b-tab-item>
                        <b-tab-item label="Ant. Personales Patológicos"></b-tab-item>
                        <b-tab-item label="Antecedentes laborales"></b-tab-item>
                        <b-tab-item label="Exploración Física"></b-tab-item>
                        <b-tab-item label="Obs"></b-tab-item>
                        <b-tab-item label="Ant. no patológicos"></b-tab-item>
                        <b-tab-item label="Pad. Actual"></b-tab-item>
                      </b-tabs>
                    </div>
                  </div>
                </b-tab-item>
                <b-tab-item v-if="false" label="Kinantro" value="kinan"></b-tab-item>
                <b-tab-item v-if="false" label="Espirometria" value="espiro"></b-tab-item>
                <b-tab-item v-if="false" label="Odontologia" value="odonto"></b-tab-item>
                <b-tab-item v-if="false" label="Revisión de proctologia" value="procto"></b-tab-item>
                <b-tab-item v-if="false" label="Ginecologia" value="gineco"></b-tab-item>
                <b-tab-item v-if="false" label="Rayos x" value="xray">
                  <xray :data="dataXray" @selecttemplate="handleSelectedXrayTemplate"
                        v-if="data.patient && data.specialties.XRay"></xray>
                  <div v-else>
                    <section class="section">
                      <div class="content has-text-grey has-text-centered">
                        <p><span class="icon is-large"><i class="fas fa-box-open fa-2x"></i></span></p>
                        <p>Esta orden no contiene rayos x</p>
                      </div>
                    </section>
                  </div>
                </b-tab-item>
                <b-tab-item v-if="false" label="Resumen" value="resumen1">
                  <summary1></summary1>
                </b-tab-item>
                <b-tab-item v-if="false" label="Resumen" value="resumen2"></b-tab-item>
                <b-tab-item v-if="false" label="Otros" value="otros"></b-tab-item>
                <b-tab-item v-if="false" label="Oftalmologia" value="ofta"></b-tab-item>
                <b-tab-item v-if="false" label="Colposcopia" value="colpos"></b-tab-item>
                <b-tab-item v-if="false" label="Mastografia" value="masto"></b-tab-item>
                <b-tab-item v-if="false" label="Holter" value="holter"></b-tab-item>
                <b-tab-item v-if="false" label="Mapa" value="mapa"></b-tab-item>
              </b-tabs>
            </section>
          </div>

          <div :class="`column ${expandHistory ? 'is-3' : 'is-1'}`"
               style="border-left: 2px #dbdbdb solid">

            <b-field>
              <b-tooltip :label="expandHistory ? 'Ocultar Historial' : 'Mostrar Historial' ">
              <b-switch v-model="expandHistory"></b-switch>
              </b-tooltip>
            </b-field>
            <historical v-if="expandHistory" :data="historial" @selectorder="handleSelectOrder"></historical>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showHistoryModal" v-if="!!data.ordenNew">
      <div style="background: white">
        <OrderHistory :order="{order_new: data.ordenNew, id: data.id}"/>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import SelectCompany from "@/components/company/SelectCompany";
import SelectPatient from "@/components/patient/SelectPatient";
import medicalspecialties from "@/store/modules/medicalspecialties";
import Electrocardiogram from "@/components/medical-specialties/Electrocardiogram";
import StressTest from "@/components/medical-specialties/StressTest";
import Xray from "@/components/medical-specialties/Xray";
import Historical from "@/components/medical-specialties/Historical";
import Summary1 from "@/components/medical-specialties/Summary1";
import OrderHistory from "../../components/order/OrderHistory.vue";


export default {
  name: "Index",
  components: {
    Electrocardiogram,
    StressTest,
    Xray,
    Historical,
    Summary1,
    SelectCompany,
    SelectPatient, OrderHistory
  },
  props: {},
  data() {
    return {
      order: '',
      barcode: '',
      orderDate: '',
      data: {},
      year: (new Date()).getFullYear() % 10,
      loading: false,
      dataXray: [],
      dataElectro: {},
      historial: [],
      showHistoryModal: false,
      dataStressTest: {},
      expandHistory: false
    }
  },
  computed: {
    ...mapState('medicalspecialties', ['company']),

    yearsRange() {
      return this.$alfalab.yearsRange();
    }
  },

  mounted() {
    this.wrapper = document.getElementById('main-wrapper');
    this.wrapper.classList.add('is-expanded');
    // this.getData()

  },
  methods: {
    getDataElectro(order) {
      //console.log(order);
      this.$loader.show();
      const params = {};
      this.$http.get(`medical-specialties/show-electro/${this.year}${this.order.toString().padStart(7)}`, {params}).then(async ({data}) => {
        this.dataElectro = data.data;
        this.getHistorial('electro');
      }).catch(errors => {
        //this.$toast.error('No se encontró la orden ingresada favor de verificarla');
      }).finally(() => {
        this.loading = false;
        this.$loader.hide();
      });
    },
    getDataStressTest(order){
      this.$loader.show();
      const params = {};
      this.$http.get(`medical-specialties/show-stress-test/${this.year}${this.order.toString().padStart(7)}`,{params}).then(async ({data}) => {
        this.dataStressTest = data.data;
        this.getHistorial('stress-test');
      }).catch(errors => {

      }).finally(() => {
        this.loading = false;
        this.$loader.hide();
      });
    },
    getDataByBarcode(){
      this.$loader.show();
      const params = {};
      this.$http.get(`medical-specialties/xray/${this.barcode}/barcode`, {params}).then(async ({data}) => {
        this.setOrderData(data);
      }).catch(errors => {
        this.$toast.error('No se encontró la orden ingresada favor de verificarla');
      }).finally(() => {
        this.loading = false;
        this.$loader.hide();
      })
    },
    getData() {
      this.$loader.show();
      const params = {};
      this.$http.get(`medical-specialties/xray/${this.year}${this.order.toString().padStart(7)}`, {params}).then(async ({data}) => {
        this.setOrderData(data);
        this.barcode = '';
      }).catch(errors => {
        this.$toast.error('No se encontró la orden ingresada favor de verificarla');
      }).finally(() => {
        this.loading = false;
        this.$loader.hide();
      })
    },
    handleClick(el) {
      if(!this.order) { return ;}
      switch (el) {
        case 'esfprue':
          //console.log('click esfuerzo');
          this.getHistorial('stress-test');
          break;
        case 'electro':
          //console.log('click electro');
          this.getHistorial('electro');
          break;
        case 'xray':
          this.$alfalab_specialities.loadXray(this, this.year + this.order.padStart(7)).then(data => {
            this.dataXray = data;
          });
          break;
      }

     // console.log('Se hizo clic en el botón', el);
    },
    navigate(step) {
      this.$loader.show();
      const params = {year: this.year};
      if (this.order && this.data.ordenNew) {
        params['order'] = this.data.ordenNew;
      }
      this.historial = []
      this.$http.get(`/medical-specialties/navigate-all/${step}`, {
        params
      }).then(async ({data}) => {
        //  await this.$store.dispatch('medicalspecialties/setOrder', data.data);
        //  this.ordenNew = data.order;
        this.setOrderData(data)
      }).catch(errors => {
        this.$alfalab.showError(errors);
        this.$store.dispatch('medicalspecialties/setOrder', {});
      }).finally(() => {
        this.barcode = '';
        this.$loader.hide();
      })
    },
    setOrderData(data) {
      this.data = data.data;
      this.order = data.data.order;
      setTimeout(() => {
        if (data.data.specialties.Electro) {
          this.getDataElectro(data.data.order);
        }
        if (data.data.specialties.StressTest) {
          this.getDataStressTest(data.data.order);
        }
        if (data.data.specialties.XRay) {
          this.getDataXRay(data.data.order);
        }
      }, 100)
    },
    handleSelectedXrayTemplate({data, selected}) {
      const nuevaDataXray = this.dataXray.slice();
      nuevaDataXray[selected].header = data.header;
      nuevaDataXray[selected].findings = data.findings;
      nuevaDataXray[selected].conclusion = data.conclusion;
     // console.log(data, selected, nuevaDataXray);
      this.dataXray = nuevaDataXray;
    },
    getHistorial(type) {
      this.$loader.show();
      this.$http.get(`/medical-specialties/historial/${this.data.patient.code}/${type}`).then(({data}) => {
      //  console.log('historial', data.data);
        this.historial = data.data;
      }).finally(() => {
        this.$loader.hide();
      })
    },
    handleSelectOrder({year, order}) {
   //   console.log(year, order);
      this.year = year;
      this.order = order;
      this.getData();
    },
  },
}
</script>

<style scoped>

</style>